/*********************************************************** commun ***********************************************************/
//fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import 'react-big-calendar/lib/sass/styles';
//colors
$primary: #c10058;
$secondary: #50E3C2;
$colorTxt1: #1F3149;
$colorTxt2: #9AA6B7;
$colorBackground: #EFF2FF;
$colorGreenBack: #00E5AC;
$colorGreenBorder: #28C49D;
$alertButton: #CC1A1A;

$quarterHeight: 15px;

#root{
  padding: 20px 20px 20px 0;
}
body{
  font-family: Montserrat !important;
}
.w100 {
  font-weight: 100;
}
.w200 {
  font-weight: 200;
}
.w300 {
  font-weight: 300;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.wBold {
  font-weight: bold;
}
.font-quicksand-class{
  font-family: Quicksand;
}
//spacer
.spacer{
  padding: 20px;
}
.spacer-10{
  padding: 10px;
}
label{
  font-size: 12px;
}
.center-text{
  text-align: center !important;
}
.space-between-flex{
  justify-content: space-between !important;
}
//link
a {
  text-decoration: none !important;
  color: $colorTxt1 !important;
}
//alerts badges
.bg-alert-danger{
  background-color: $alertButton;
  color:  white;
}
.alert-with-radius{
  text-align: center;
  background-color: $alertButton;
  color:  white;
  border-radius: 20px;
  padding: 20px;
}
.bg-success-custom{
  background-color: $colorGreenBack !important;
  border-color: $colorGreenBack !important;
  cursor: pointer;
}
.bg-success-custom:hover{
  border-color: $colorGreenBack !important;
  opacity: 0.8 !important;
}
.bg-archive-custom{
  background-color: $primary !important;
  border-color: $primary !important;
  cursor: pointer;
}
.bg-archive-custom:hover{
  border-color: $primary !important;
  opacity: 0.8 !important;
}
.bg-danger{
  background-color: $alertButton !important;
  cursor: pointer;
}
.bg-warn{
  color: #fff !important;
  cursor: pointer;
}
.bg-enpc{
  color: #fff !important;
  cursor: pointer;
  background-color: #F47954 !important;
  padding-top: 7px !important;
}
.table-badge{
  border-radius: 25px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 10px !important;
  width: 10px !important;  
  padding: 2px !important;
}  
.prestations-u-badge{
  border-radius: 5px !important;
  margin-right: 4px !important;
  padding: 2px 10px !important;
}  
.header-timesheet{
  .p-color{color: $alertButton !important;}
  p{
    margin: 1px !important;
  }
}
.header-timesheet-table{
  tr th {color: $colorTxt1;}
} 
.confirmation-alert{
  font-size: 11px !important;
}
//errors
.error-message{
  color: $alertButton;
}

//titles
.main-primary-title{
  font-family: Montserrat !important;
  text-transform: lowercase;
  font-size: 48px;
  font-weight: bold;
}
.main-primary-title::first-letter{
  text-transform: capitalize !important;
}
.main-secondary-title{
  font-size: 20px;
  font-weight: bold;
  color: $colorTxt2;
  text-transform: lowercase;
}
.main-secondary-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-main-title{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  text-transform: lowercase;
  padding-left: 20px !important;
}
.blocs-main-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-secondary-title{
  font-size: 13px;
  font-weight: bold;
  color: $colorTxt2;
  margin-bottom: 0px;
  padding-left: 20px !important;
}
.small-title{
  font-family: Quicksand !important;
  font-size: 16px;
  font-weight: bold;
  color: $colorTxt2;
  letter-spacing: 2px;
}
.small-title::first-letter{
  text-transform: capitalize !important;
}
.small-seconde-title{
  font-family: Quicksand !important;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: $colorTxt2;
}
.small-seconde-title::first-letter{
  text-transform: capitalize !important;
}
.paragraph{
  text-align: justify;
  font-size: 12px;
  color: $colorTxt2;
}
//scroll main hidden
.main::-webkit-scrollbar { width: 0 ! important }
.main { overflow: -moz-scrollbars-none; }
.main { -ms-overflow-style: none; }
//app body
.app-body {
  color: $colorTxt1;
  display: flex;
}
//main footer
.main-custom-footer{
  padding-top: 20px;
  display: flex !important;
  justify-content: center !important;
}
.link-footer{
  background-color: $alertButton;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: white !important;
}
//green bar
.green-bar{
  width: 100px;
  background-color: $colorGreenBack;
  height: 2px;
}
.inputs{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  height: 45px !important;
}
.inputs:focus {
  outline: 0px !important;
  box-shadow: none !important;
}
.border-inputs-color{
  border: 1px solid #9AA6B7 !important;
}
button:focus {
  outline: 0px !important;
}
.inputs-only-show{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 80% !important;
}
.inputs-only-show:focus {
  outline: 0px !important;
}
textarea:focus {
  outline: 0px !important;
}

.inputs-txt-area{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  overflow: hidden;
}
.input-payment-card{
  padding: 15px 15px !important;
  margin-bottom: 10px !important;
  border-radius: 23px !important;
  border: 1px solid #9AA6B7;
  width: 100% !important;
  height: 45px !important;
  font-size: 0.7em;
  line-height: 1.2em;
  font-family: sans-serif;
}
.icon-cards{
  text-align: center;
}
.input-payment-card:focus {
  outline: 0px !important;
}

.custom-file-label {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  height: 45px !important;
  overflow-wrap: break-word !important;
  text-overflow: ellipsis !important;
}
.custom-file-label::after {
  display: none !important;
 }
.icon-file-input{
  padding-top: 6px;
  padding-left: 20px;
}
.icon-file-input:hover{
  cursor: pointer;
  opacity: 0.4;
}
//currsor pointer
.curssorPointer{
  cursor: pointer;
}
.curssorPointer:hover{
  transform: scale(1.01)
}
.noCurssorPointeur{
  cursor: default !important;
}
.active{
  color: #C10058
}
.redirect-links-bloc{
  font-size: 12px;
}
.error-message{
  font-size: 11px;
}
.crediter-temps-span{
  font-size: 8px;
  b{
    padding: 2px;  
  }
}
.sub-item-nav{
margin-left: 100px !important;
}
.space-between{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.stats-phrase{
  font-family: Quicksand !important;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $primary;
}
.iframe_stats{
  position: relative;
  display: initial;
  width: 100vh !important;
  height: 100vh !important;
  border: 0px;
}
.schedule{
  justify-content: center;
  label {
    font-size: 11px !important;
    padding-top: 10px;
    font-weight: bold;
  }
}
.schedule-border{
//  border: solid 1px $colorTxt2;
}
.schedule-switch{
  margin-top: 10px;
  margin-left: 5px;
  label{
    padding-top: 3px;
  }
}
.planification-line{
  display: flex !important;
  justify-content: space-between !important;
}
.rbc-allday-cell{
  display: none !important;
}
.drop-down-header{
  cursor: pointer;
  justify-content: flex-end;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.drop-down-header-div{
  justify-content: left;
  height: 55px;
  border: $colorTxt2 solid 1px;
  border-radius: 25px;
  background-color: white !important;
  padding:  2px 20px;
}
.drop-down-header-link{
  padding: 15px 10px;
  margin : 0px !important;
  justify-content: left;
}
.avatar{
  vertical-align: middle;
  width: 50px !important;
  height: 50px !important;
  border: solid 1px $colorTxt2;
}
.drop-down-header-input{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  height: 50px !important;
}
.drop-down-header-input:focus {
  outline: 0px !important;
}
.search-input{
  padding: 2px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  //height: 45px !important;
  background-color: #ffffff;
  .css-15e6m3o-control{
    border-radius: 23px !important;
  }
  .css-yk16xz-control{
    border: 0px !important;
  }
  .css-g1d714-ValueContainer:focus{
    outline: 0px !important;
  }
 div{
   border-radius: 13px !important;
 }
}
.search-input:focus {
  outline: 0px !important;
}
.rich-editor-wraper{
  width: 100%;
  border: 1px solid gray;
}
.rich-editor-content{
  width: 100%;
  height: 400px !important;
  max-height: 400px !important;
}
.collapse{
  width: 100% !important;
}
.collapse-custom{
  border: 1px $colorTxt2 solid;
}
.collapse-custom-header{
  background-color: $colorBackground;
  cursor: pointer;
  font-size: 12px;
}
.collapse-custom-header:hover{
  background-color: rgba(80,227,194, 0.3);
}
.time-credit-header{
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: 1px solid $colorTxt2;
  cursor: pointer;
}
.time-credit-header:hover{
  background-color: rgba(80,227,194, 0.3);
}
.time-credit-body{
  border: 1px solid $colorTxt2;
  border-bottom: 3px solid $colorTxt2;
  padding: 10px !important;
  background-color: #ffffff;
}

.time-credit-bloc{
  font-size: 12px;
  label{
    font-size: 12px;
    font-weight: bold;
  }
  .intern-bloc{
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover{
      background-color: $colorBackground;
    }
  }
}
.bullet-custom{
  font-weight: bold;
  font-size: 9px;
  background-color: $colorTxt2;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
}
.bg-normal{
  color: #EFF2FF !important;
  background-color: $colorTxt1 !important;
}
.bg-danger-custom{
  color: #EFF2FF !important;
  background-color: $alertButton !important;
}

//z-index of google places prediction list
.pac-container {
  z-index: 1051 !important;
}
.send-file-form{
  padding-left: 20px;
  padding-right: 20px;
}
.html-decription{

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  * {
    font-size: 12px !important;
  }
}
.custom-switch{
  z-index: 0 !important;
}
.custom-format{
  font-size: 11px;
  padding-left: 15px;
}
.no-active-session{
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  color: $colorBackground !important;
  p{
    text-align: center;
    background-color: $primary;
    padding: 20px;
    a {
      color: $colorBackground !important;
    }
  }
}
.description-table{
  font-size: 11px;
  font-style: italic !important;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  span{
    font-style: normal;
  }
}
.uploaded-file{
  background-color: $colorBackground;
}
.cut-content{
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display:inline-block;
}
.included-in-plan{
  background-color: $colorBackground;
  font-size: 11px !important;
  font-style: italic;
}
.infos{
  font-size: 12px;
}
/*********************************************************** planification ****************************************************/
.full-screen-planification{
  padding-left: 20px !important;
  height: 95vh !important;
}
.custom-event-wraper{
  width: 100% !important;
}
.event-agenda-name{
  font-weight: bold;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.event-participant-name-wrap{
  flex-wrap: wrap;
}
.event-participant-name{
  background-color: $colorBackground;
  margin: 1px;
  padding: 1px;
  border-radius: 5px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.tooltip-inner{
  background-color:white !important;
  color: black !important;
  padding: 20px !important;
  min-width: 400px !important;
  text-align: left !important;
  overflow-wrap: break-word;
  -webkit-box-shadow: 0px 5px 15px -10px #000000, 5px 5px 5px 5px rgba(0,0,0,0); 
  box-shadow: 0px 5px 15px -10px #000000, 5px 5px 5px 5px rgba(0,0,0,0);
  opacity: 1.2 !important;
  .tooltips-agenda-name{
    font-weight: bold !important;
    font-size: 13px;
  }
}
//new planning
.planning-header-custom{
  border: 0px !important;
  background-color: $colorGreenBack;
  color: #fff;
}
.holiday-text{
  font-size: 8px;
  position: absolute;
  top: 0px;
  margin-left: 5px;
}
.custom-font-size{
  font-size: 12px !important;
}
/*********************************************************** invoice **********************************************************/
.prices-bottom-bloc-row{
  padding: 30px;
}
.prices-bottom-bloc{
  display: flex;
  padding-bottom: 10px ;
  justify-content: space-between;  
  border-bottom: 1px solid $colorBackground;
}
/*********************************************************** modal ************************************************************/
.force-font{
  *{
    font-family: Montserrat !important;
  }
}
.modal-lg-custom{
  max-width: 70% !important;
}
.nested-modal-lg-custom{
  max-width: 67% !important;
  padding: 0px !important;
//  margin-top: 5% !important;
}
.modal-header-bloc{
  padding: 20px 40px 10px 40px !important;
  justify-content: space-between;
}
.modal {
  z-index: 1500 !important;
 }
.toast-custom{
  border-radius: 20px !important;

}
.toast-header-bloc{
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  strong{
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
.toast-body-bloc{
  text-align: center;
  font-weight: bolder;
}
.color-success{
  color: $colorGreenBack !important;
}
.color-danger{
  color:  $alertButton !important;
}
.color-warning{
  color:  #ffc107 !important;
}
.color-secondary{
  color: #6c757d !important;
}
/*********************************************************** agenda ************************************************************/
.quarter{
  height: $quarterHeight;
  width: 100%;
  box-sizing: border-box;
}
.rbc-today{
  background-color: #fff;
}

/*********************************************************** tables ************************************************************/
.table-bloc{
  font-size: 12px;
  background-color: #fff;
  border-radius: 20px;
  text-align: left;
  td{
    vertical-align: middle !important;
    padding: 8px 18px !important;
    height: 50px !important;
  }
  th{
    border: 0px !important;
    color: $colorTxt2;
    padding-left: 20px !important;
  }
  thead th input{
    font-size: 13px !important;
  }
}
.table-bloc-custom{
  font-size: 12px;
  background-color: #fff;
  border-radius: 20px;
  text-align: left;
  td{
    vertical-align: middle !important;
    padding: 8px 18px !important;
    height: 50px !important;
  }
  th{
    border: 0px !important;
    color: $colorTxt2;
    padding-left: 20px !important;
  }
  thead th input{
    font-size: 12px !important;
  }
}
.custom-first-elemnt thead tr th:first-child, tbody tr td:first-child {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  word-break: break-all;
}
.search-input-table{
  height: 38px !important;
}
.open-hours{
  tr{
    border-bottom: 1px solid !important;
  }
}
/*********************************************************** tabs **************************************************************/
.flow-up-tabs-temporaire{
  background-color: $colorBackground;
  li{
    width: 50% !important;
    text-align: center;
  }
}

.flow-up-tabs{
  background-color: $colorBackground;
  li{
    width: 33.33% !important;
    text-align: center;
  }
}
.flow-up-tabs-content{
  padding: 40px 0 40px 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color:$colorGreenBorder !important;
  background-color: $colorGreenBack !important;
}
.tabs-bloc{
  border: 1px $colorTxt2 solid;
  padding: 20px;
}
.show-plus{
  cursor: pointer;
//  padding: 20px;
  margin: 10px;
}
.capitalize-word {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
}

/*********************************************************** buttons ***********************************************************/
.green-button{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  padding: 5px 70px 5px 70px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  height: 45px !important;
}
.green-button:disabled{
  background-color: $colorTxt2 !important;
  border-color: $colorTxt2 !important;
  cursor: not-allowed !important;
}
.green-button-planification{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
//  padding: 5px 50px 5px 50px;
  cursor: pointer !important;
  height: 35px !important;
}
.green-button-planification:disabled{
  background-color: $colorTxt2 !important;
  border-color: $colorTxt2 !important;
  cursor: not-allowed !important;
}

.green-button-planification:hover{
  transform: scale(1.01);
  opacity: 0.8
}

.green-button-new-planification{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
//  padding: 5px 50px 5px 50px;
  cursor: pointer !important;
  height: 35px !important;
  width: 100% !important;
}
.other-button-new-planification{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorTxt2 !important;
  border: 1px solid $colorTxt2 !important;
  border-radius: 30px !important;
//  padding: 5px 50px 5px 50px;
  cursor: pointer !important;
  height: 35px !important;
}
.other-button-new-planification:hover{
  transform: scale(1.01);
  opacity: 0.8
}
.login-button{
  text-align: center;
  color: #C10058 !important;
  background-color: transparent;
  border: 3px solid #C10058;
  border-radius: 30px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer;
  width: 97%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.table-button{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin: 0px !important;
  font-size: 12px !important;
}
.add-button{
  padding: 10px;
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin: 0px !important;
  font-size: 12px !important;
}
.add-button-no-cursor{
  padding: 2px !important;
  cursor: default !important;
  font-size: 10px !important;
}
.input-table{
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
}
.button-show-details{
  border: 0;
  color: #fff !important;
  background-color: $colorGreenBack;
  cursor: pointer !important;
  margin-left: 10px !important;
  font-size: 11px !important;
  border-radius: 30px !important;
}
.button-show-details:hover{
  transform: scale(1.01);
  opacity: 0.8
}
/*********************************************************** login page ******************************************************/
.login-page{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}
.login-form{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.login-form div{
  padding: 20px 0px 20px 0px;
}
.login-preloader{
  display: flex !important;
  justify-content: center !important;
}
.login-preloader div {
  padding: 0px !important;
}
.backgroud-image-home{
  background-image: url("./assets/bg/inris_login_bg.jpg");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-passe-forget:hover{
  cursor: pointer;
  text-decoration: underline;
}
.bottom-fixed{
  margin: 0;
  position: absolute;
  bottom: 2%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.radio-choices{
  p{
    padding: 15px 15px 15px 0;
    input{
      margin-right: 5px;
      height: 25px;
      width: 25px;
    }
  }
  .badge{
    padding: 2px;
    margin-top: -3px !important;
    vertical-align: text-top !important;  
  }
}
/*********************************************************** blocs ***********************************************************/
.bloc-commun-style{
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  margin: 20px 0;
  align-items: center;
  opacity: 1;
  word-break: break-word;
}
.bloc-commun-style:hover{
  transform: scale(1.01)
}
.blocs-results{
  text-align: center;
}
.blocs-results span{
  font-size: 38px;
  font-weight: bold;
}
/**** header ****/
.navbar-light{
  background-color: $colorBackground !important;
  height: 160px;
  border-radius: 40px 40px 0 0 ;
}
.navbar{
  padding: 0px !important;
}
.app-header{
  height: 160px;
  right: 50px;
  .navbar-toggler{
    display: none;
  }
  .navbar-brand {
    width: 20%;
    height: 100%;
    background-color: #fff;
  }
  .navbar-brand img{
    width: 170px;
  }
  .navbar-nav{
    height: 130px;
    width: 80%;
    padding: 0 30px;
    margin-top: 30px;
    border-radius: 40px 40px 0px 0;
    justify-content: space-between;
    background-color: $colorBackground;
  }
  .md-avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border: solid 1px;
  }
}
.toggle-menu-custom{
 // position: fixed;
//  bottom: 0px;
//  top: 95%;
  text-align: right;
  margin-bottom: 5px;
  padding-left: 8px;
  padding-right: 17px;
  span{
    background-color: $colorBackground;
    padding: 5px;
  }
  span:hover{
    background-color: transparent;
    cursor: pointer;
  }

}
/*********************************************************** sidebar ***********************************************************/
.sidebar {
  width: 20%;
  text-align: center;
  .navbar-nav{
    width: 100%;    
    padding-top: 40px;
  }
  .nav-item {
    padding: 10px 10px 10px 10px;
    width: 100%;
    display: flex;
    letter-spacing: 0px;

    font-size: 14px;
    font-weight: bold;
    a{
      color: #9AA6B7 !important;      
    }
    span{
      color: #9AA6B7 !important;      
      margin-left: 40px !important;
      margin-right: 0px !important;
    }
  }

  .nav-item:hover {
    background-color:#EFF2FF ;
    a{
      color: $colorTxt1;
    }
    span{
      color: $colorTxt1;
    }
  }

  .navbar-brand img{
    width: 160px !important;
  }

  .nav-item.active {
    color: $primary;
    background-color: $colorBackground;
    a{
      color: $colorTxt1;
    }
  }
  .nav-item.active:hover {
    color: $secondary;
    background-color: #fff;
  }
  .sidebar-footer{
    background-color: #fff;
  }
  .sidebar-minimizer {
    display: none;
  }
}
.sideBarMin{
  width: 50px;
  text-align: center;
  .navbar-nav{
    width: 100%;    
    padding-top: 40px;
  }
  .nav-item {
    padding: 5px 5px 5px 12px;
    width: 50px;
    display: flex;
    letter-spacing: 0px;
    border-top: 1px solid $colorTxt2;

    font-size: 14px;
    font-weight: bold;
    a{
      padding: 0px !important ;
      color: #9AA6B7 !important;      
    }
    span{
      display: none;
    }
  }

  .nav-item:hover {
    background-color:#EFF2FF ;
    a{
      color: $colorTxt1;
    }
    span{
      color: $colorTxt1;
    }
    width: 48px !important;
  }

  .nav-item.active {
    color: $primary;
    background-color: $colorBackground;
    a{
      color: $colorTxt1;
    }
    width: 48px !important;
  }
  .navbar-brand img{
    padding-left: 5px;
    width: 45px !important;
  }
  .sub-menu-custom{
    padding-left: 17px;
    border-top: 0px;

  }  
}
/*********************************************************** main ***********************************************************/
.main{
  background-color: $colorBackground;
  overflow: auto;
  border-radius: 40px 40px 40px 40px;
  width: 100%;
  min-height: 90vh;
  padding: 30px;
  .main-footer{
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 1030;
    background-color: #fff;
  }
}
/*********************************************************** links and others ***********************************************************/
.links{
  cursor: pointer;
  text-decoration: underline !important;
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  color: $colorTxt2 !important;
}
.links:hover{
  text-decoration: none !important;
}
.img-redirect-links{
  margin: 20px 20px 20px 0px;
  width: 100%;
  height: auto;
  min-height: 300px;
  border-radius: 20px !important;
}
.avatar-redirect-links{
  margin: 1.2em !important;
  width: 130px;
  height: 130px;
  min-height: 130px;
  border: solid 1px $colorTxt1;
  border-radius: 50% !important;
}
////////////////eval click
.eval-right{
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.eval-link{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  padding: 5px 10px;
  overflow-wrap: break-word;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
}
.eval-volume{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $colorBackground;
  padding: 5px 10px ;
}
.eval-span{
  background-color: $colorBackground;
}

/**********************************************************************************************************************************/
/*********************************************************** responsive ***********************************************************/
/**********************************************************************************************************************************/
@media (max-width: 1800px) {
  li{
    font-size: 11px !important;
  }
  .main-secondary-title {
    font-size: 18px;
  }
  .sidebar .nav-item {
      font-size: 11px;
  }
  .main-primary-title {
      font-size: 30px;
  }
  .small-title {
      font-family: Quicksand !important;
      font-size: 11px;
      font-weight: bold;
      color: #9AA6B7;
      letter-spacing: 2px;
  }
  .green-button {
    padding: 5px 10px 5px 10px;
    width: 100%;
    font-size: 12px !important;
  }
}

@media (max-width: 1440px) {
  .space-between{
    display: block !important;
  }
  .sidebar{
    width: 25% !important;
  }
  .app-header .navbar-nav {
    width: 75%;
  }
  .app-header .navbar-brand {
    width: 25%;
  }
}
@media (max-width: 1200px) {
  .sidebar{
    width: 28% !important;
  }
  .app-header .navbar-nav {
    width: 72%;
  }
  .app-header .navbar-brand {
    width: 28%;
  }
  .main-secondary-title {
    font-size: 16px;
  }
  .sidebar .nav-item {
      font-size: 11px;
  }
  .main-primary-title {
      font-size: 30px;
  }
  .small-title {
      font-family: Quicksand !important;
      font-size: 11px;
      font-weight: bold;
      color: #9AA6B7;
      letter-spacing: 2px;
  }

 //planning page
 .rbc-toolbar{
  justify-content: flex-start !important;
    .rbc-btn-group{
      width: 100% !important;
      text-align: center !important;
    }
    .rbc-toolbar-label{
      text-align: center !important;
      width: 100% !important;
    }
    .rbc-btn-group{
      text-align: center !important;
      width: 100% !important;
    }
  }
  //statistiques page
  .chart-bar-wraper{
    display: flex !important;
    justify-content: center !important;
  }
  //tabl bloc des applications enpc
  .table-bloc-custom{
    font-size: 10px;
    background-color: #fff;
    border-radius: 20px;
    text-align: left;
    td{
      vertical-align: middle !important;
      padding: 4px 8px !important;
      height: 50px !important;
    }
    th{
      width: 20% !important;
      border: 0px !important;
      color: $colorTxt2;
      padding-left: 0px !important;
    }
    th:first-child{
      width: 5px !important;
    }

    thead th input{
      font-size: 12px !important;
    }
  }
  .flow-up-tabs{
    a{
      font{
        font-size: 9px !important;
        padding: 0px !important;
      }
    }
  }
  //button de recherche au dessus de la table paging
  .input-group-append{
    .input-group-text{
      display: none !important;
    }
  }
  .img-redirect-links{
    margin: 10px 20px 20px 0px;
  } 
  
}


@media (max-width: 800px) {
  .app-body {
    display: block !important;
  } 
/*   .sidebar{
    padding: 0px !important;
    background-color: $colorBackground !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row-reverse !important;
  }
 */  
 .sideBarMin{
   background-color: #fff;
   border:$colorTxt2 1px solid;
    position: fixed;
    top: 16%;
    right: 10px;
    z-index: 50000 !important;
    width: 50px;
    text-align: center;
    .navbar-nav{
      width: 100%;    
      padding-top: 0px;
    }
    .navbar-brand {
      display: none;
    }
  
    .sub-menu-custom{
      padding-left: 17px;
      border-top: 0px;
    }  
  }
  .toggle-menu-custom{
    display: none;
  }
  .navbar-toggler-icon {
    width: 25px !important;
    height: 25px !important;
    background: no-repeat center/100% url("./assets/logo/logo-min.png") !important;
  }
  .drop-down-header {
    justify-content: flex-start;
    padding-left: 10px;
  }
  .drop-down-header-input{
    margin-top: 7px !important;
  }
  .dropdown{
    margin-top: 7px !important;
  }
  #root{
    padding: 0;
    font-size: 12px;
  }
  .padding-x-responsive{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .padding-top-responsive{
    padding-top: 20px;
  }
  // commun 
  .app-body {
    //margin-top: 70px !important;
  }
  .drop-down-header{
    cursor: pointer;
    justify-content: left;
  }
  .drop-down-header span{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }  
  .app-header{
    height: 70px;
    right: 0px;
    border: 0;
  }
  .app-header .navbar-brand img {
    width: 110px; 
  }
  .app-header .navbar-toggler {
    display: block;
  }
  .app-header .navbar-nav {
    background-color: transparent;
    height: 70px;
    padding: 0;
    margin: 0px;
    border-radius: 0px;
  }
  .app-header .navbar-brand {
    width: 155px;
    height: 100%;
    background-color: transparent;
  }
  .sidebar {
    width: 100% !important;
    height: 100% !important;
    left: 0;
    padding-left: 0px;
  }
  .backgroud-image-home{
    display: none;
  }
  .bottom-fixed{
    bottom: 0 !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0px !important;
  }
  .logo-custom{
    img{
      width: 60% !important;
      height: auto !important;
    }
  }
  .login-form{
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .login-form div {
    padding: 10px 0px 10px 0px;
  }
  .login-button{
    width: 100% !important;
  }
  .main{
    margin: 0px !important;
    padding: 20px !important;
    position: initial;
    border-radius: 0;
    width: 100%;
    .main-footer{
      height: 0px;
      width: 100%;
      position: fixed;
      bottom: 0px;
      z-index: 1030;
      background-color: #fff;
    }
  }
  .d-flex{
    display: block !important;
  }
  .green-button {
    padding: 5px 40px 5px 40px;
  }
  .prix{
    font-size: 48px !important;
  }
  .main-primary-title{
    font-size: 20px;
  }
  .main-secondary-title{
    padding-top: 20px;
    font-size: 15px !important;
  }
  .blocs-results{
    span{
      font-size: 28px;
    }
  }
  .blocs-main-title{
    padding-left: 0px !important;
  }
  .blocs-secondary-title{
    padding-left: 0px !important;
  }
  .icons-and-infos{
    justify-content: space-between;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .icons-and-infos span{
    padding-left: 20px;
  }
  .permis-title{
    font-size: 14px;
    font-weight: bold;
  }
  .agencies-list{
    p{
      font-size: 9px;
    }
  }
  .reverse-responsive{
    flex-direction: column-reverse;
  }
  .padding-responsive{
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-custom-footer{
    text-align: center;
    display: block !important;
  }
  .width-mob{
    width: 100%;
  }
  
}

@media (max-width: 460px) {
  .drop-down-header-input{
    width: 55% !important;
  }
}
@media (max-width: 360px) {
  .drop-down-header-input{
    width: 50% !important;
  }
}
@media (max-width: 320px) {
  .drop-down-header-input{
    width: 45% !important;
  }

  .bottom-fixed{
    bottom: -20px !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0px !important;
  }

}